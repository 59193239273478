import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import HTMLReactParser from "html-react-parser";
import { isBrowser, isMobile } from "react-device-detect";
import Slider from "react-slick";

import { Arrow, Pdf } from "components/icons";

import { black } from "theme/colors";
import { breakpoints } from "utils/mixins";

//STYLES
const Wrapper = styled.div`
  height: auto;
  min-height: 100vh;
  padding: 100px 0;
  width: 100%;
  background-color: ${(props) => props.dataColor};
  color: ${(props) => props.dataText};
  .docControls {
    padding: 0 20px !important;
    display: flex;
    left: 50%;
    bottom: 80px;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1440px;

    position: absolute !important;

    justify-content: space-between;
    .prevBut {
      width: 40px;
      height: 20px;
      svg {
        height: 40px;
        transform: rotate(90deg);
      }
    }
    .nextBut {
      width: 40px;
      height: 20px;
      svg {
        height: 40px;
        transform: rotate(-90deg);
      }
    }
  }
  .sliderWrapper {
    padding: 20px;
    * {
      outline: none !important;
    }

    .slick-list {
      overflow: visible !important;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 33%;
      z-index: 9999;
      transition: 500ms;
      transform: translateX(-100%);
      background-color: ${(props) => props.dataColor};
    }
    &--move {
      &::before {
        transform: translateX(-200%);
      }
    }
  }
  .slide {
    img {
      display: block;
      width: 100%;
      max-width: 45vh;
      max-height: 45vh;
    }

    .link-text {
      margin-top: 15px;
      font-family: "Futura PT Book";
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
      color: ${black.default};

      color: ${(props) => props.dataText};
      span {
        padding-left: 15px;
      }
    }
  }
  .container {
    padding: 0 20px 15px;
    position: relative;

    h2,
    .h2 {
      margin: 0;
      margin-top: 40px;

      text-align: left;
      font-family: "Futura PT";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2px;
      text-transform: uppercase;

      color: #398b59;

      color: ${(props) => props.dataText};
    }

    .slide {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media ${breakpoints.laptop} {
    padding-top: 150px;
    .slide {
      padding-right: 20%;
    }
  }
  @media (min-width: 1921px) {
    padding-top: 170px;
    .docControls {
      left: 0;
      bottom: 200px;
      transform: unset;
      width: 70%;
      max-width: unset;
    }
  }
`;
//STYLES

const Policy = (props) => {
  //PROPS
  const {
    dimensions,
    setColor,
    data: {
      page: {
        docentes: { color, introtext, link }
      }
    }
  } = props;
  //PROPS

  //DEFS
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    draggable: true,
    adaptiveHeight: false,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          // slidesToShow: link.length > 2 ? 3 : 2
          slidesToShow: 3,
          infinite: link.length > 2 ? true : false
        }
      },
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 2,
          infinite: link.length > 2 ? true : false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
    fade: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    //autoplay: true,
    autoplaySpeed: 4000,
    swipe: true,
    afterChange: (e) => {
      setSlide(e);
    }
  };
  //DEFS

  //STATES
  const [slide, setSlide] = useState(0);
  //STATES

  //REF
  const slider = useRef(null);
  //REF

  //FUNCS
  const nextSlide = () => {
    slider.current.slickNext();
    document
      .querySelector(".sliderWrapper")
      .classList.add("sliderWrapper--move");
  };

  const prevSlide = () => {
    slider.current.slickPrev();
  };
  //FUNCS

  //EFFECTS
  useEffect(() => {
    setColor(false);

    return () => {
      setColor(true);
    };
  }, [dimensions]);
  //EFFECTS

  return (
    <Wrapper dataColor={color} dataText={"white"} className="docentes">
      <div className="container" style={{ overflow: "hidden" }}>
        {introtext && HTMLReactParser(introtext)}

        {link && (isMobile || dimensions.width < 1024) && (
          <div style={{ paddingBottom: "80px" }}>
            {link.map((elem, index) => {
              return (
                <a
                  href={elem.link.url}
                  target="_blank"
                  key={index}
                  className="slide"
                >
                  <img
                    src={elem.image.localFile.childImageSharp.fluid.src}
                    srcSet={elem.image.localFile.childImageSharp.fluid.srcSet}
                  />
                  <div className="link-text">
                    <Pdf />
                    <span>{elem.link.title}</span>
                  </div>
                </a>
              );
            })}
          </div>
        )}
      </div>
      {isBrowser && dimensions.width >= 1024 && (
        <>
          <div className="sliderWrapper container">
            <Slider {...settings} ref={slider}>
              {link &&
                link.map((elem, index) => {
                  return (
                    <a
                      key={index}
                      className="slide"
                      href={elem.link.url}
                      target="_blank"
                    >
                      <img
                        alt={elem.image.altText}
                        src={elem.image.localFile.childImageSharp.fluid.src}
                        srcSet={
                          elem.image.localFile.childImageSharp.fluid.srcSet
                        }
                      />
                      <div className="link-text">
                        <Pdf />
                        <span>{elem.link.title}</span>
                      </div>
                    </a>
                  );
                })}
            </Slider>
          </div>
          {link.length > 2 && (
            <div className="container docControls">
              <button onClick={() => prevSlide()} className="prevBut">
                <Arrow color="white" />
              </button>
              <button onClick={() => nextSlide()} className="nextBut">
                <Arrow color="white" />
              </button>
            </div>
          )}
        </>
      )}
    </Wrapper>
  );
};

export const query = graphql`
  query Docentes($id: String) {
    page: wpPage(id: { eq: $id }) {
      docentes {
        color
        introtext
        link {
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 2400, quality: 100) {
                  src
                  srcSet
                }
              }
            }
          }
          link {
            target
            title
            url
          }
        }
      }
    }
  }
`;

export default Policy;
